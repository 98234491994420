
import ImgBorder from "@/components/ImgBorder.vue";
import MyButton from "@/components/MyButton.vue";
import { defineComponent } from "vue";
import Page3 from "@/page/page3.vue";
import LogInClick from "../use/LogInClick";
import { useRouter } from "vue-router";

export default defineComponent({
  components: { MyButton, ImgBorder, Page3 },
  setup() {
    const router = useRouter();
    return {
      LogInClick,
      //   data
      routerFun: () => router.push("/contact"),
    };
  },
});
