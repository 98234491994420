
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    leftObj: {
      type: Object,
      default: () => ({
        name: "名字",
        per: "概率",
      }),
    },
    backgroundImage: {
      type: String,
      default: "",
    },
    buttonName: {
      type: String,
      default: "按钮",
    },
    rightObj: {
      type: Object,
      default: () => ({
        name: "名字",
        per: "概率",
      }),
    },
  },
  name: "ImgBorder",
  setup() {
    //
  },
});
