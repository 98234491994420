<template>
  <div class="flex-row page-3 p-r align-center">
    <div class="flex-row p-a choice-box">
      <div
        v-for="ty in types"
        :key="ty.type"
        :class="{ choice: type == ty.type }"
      >
        <span @click.stop="type = ty.type" class="cur">
          {{ ty.name }}
        </span>
        <p class="p-r flex-col">
          <i></i>
        </p>
      </div>
    </div>
    <div
      v-for="(data, key) in dataTxt"
      :key="key"
      :class="[key, { choice: type == key }]"
      class="p-a flex-col align-center"
    >
      <h2 class="m0">{{ data.head }}</h2>
      <div class="text-box p-a">
        <h3 class="m0" v-html="data.txt"></h3>
        <p>{{ data.p }}</p>
        <my-button name="免费使用" @click.stop="LogInClick('login')" />
      </div>
      <div :class="key + '-img'" class="p-a"></div>
    </div>
  </div>
</template>

<script lang="ts">
import MyButton from "@/components/MyButton.vue";
import { defineComponent, ref } from "vue";
import LogInClick from "../use/LogInClick";

export default defineComponent({
  components: { MyButton },
  setup() {
    const dataTxt = {
      policy: {
        head: "缺少变现经验，广告收益提升难？",
        txt: "无需变现经验，<br/>大数据 + AI 算法 =智能化决策",
        p: "基于瀑布流分层模式，系统进行自动优化，找到广告收益的更优解",
      },
      bidding: {
        head: "缺少变现经验，广告收益提升难？",
        txt: "所有广告需求方同时竞价，最终展示价格最高的广告",
        p:
          "提供全球流行的应用内竞价（In-App Bidding）模式，让每一次广告展示获得最高价",
      },
    };
    const types = [
      { name: "智能策略", type: "policy" },
      { name: "应用内部竞价", type: "bidding" },
    ];
    const type = ref(types[0].type);
    return {
      type,
      types,
      dataTxt,
      LogInClick,
    };
  },
});
</script>

<style lang="scss" scoped>
.page-3 {
  padding-top: 4.167vw;
  width: 100%; ///;
  height: 41.667vw;
  background: linear-gradient(169deg, #142d67 0%, #000000 100%);
  color: #fff;
  .bidding,
  .policy {
    width: 100%; ///;
    height: 41.667vw;
    left: 100%; ///;
    &.choice {
      left: 0;
    }
    h2 {
      height: 3.49vw;
      // font-size: 2.5vw;
      font-size: 1.667vw;
      color: #fff;
      line-height: 3.49vw;
    }
    .text-box {
      font-size: 1.667vw;
      line-height: 2.344vw;
      left: 7.292vw;
      top: 13.223vw;
    }
    h3 {
      //   height: 4.688vw;
      font-size: 1.667vw;
      color: #fff;
      line-height: 2.344vw;
    }
    p {
      height: 1.146vw;
      font-size: 0.833vw;
      line-height: 1.146vw;
      margin: 1.25vw 0 2.5vw;
    }
  }
  .policy-img {
    width: 41.667vw;
    height: 22.396vw;
    top: 10.25vw;
    left: 47.344vw;
    background-size: 100%;
    background-image: url(~@/assets/image/page3-r1.png);
  }
  .bidding-img {
    width: 32.292vw;
    height: 24.375vw;
    top: 10vw;
    left: 57.292vw;
    background-size: 100%;
    background-image: url(~@/assets/image/page3-r2.png);
  }
  .choice-box {
    left: 7.292vw;
    top: 11.823vw;
    width: 15.104vw;
    z-index: 99;
    .cur {
      font-weight: bold;
    }
    div {
      height: 1.719vw;
      font-size: 1.25vw;
      line-height: 1.719vw;
      width: 50%;
      &.choice {
        span {
          opacity: 1;
          font-weight: 500;
        }
        p i {
          transform: translateY(0);
        }
      }
      span {
        opacity: 0.8;
      }
      &:first-of-type {
        p {
          padding-right: 2.5vw;
        }
      }
      p {
        margin-top: 1.25vw;
        background: rgba(111, 111, 111);
        opacity: 0.49;
        height: 0.521vw;
        overflow: hidden;
        i {
          height: 100%;
          top: 100%;
          background: #5ca4f1;
          box-sizing: border-box;
          transform: translateY(100%);
        }
      }
    }
  }
}
</style>
