<template>
  <div class="home flex-col p-r">
    <div class="page-1 p-r flex-col flex-center">
      <h2 class="m0 p0">移动广告聚合，获取最大化收益</h2>
      <p class="m0 p0">
        广告聚合 + 大数据 + AI + 用户场景，为您的流量创造更多的价值
      </p>
      <my-button name="免费使用" @click.stop="LogInClick('login')" />
    </div>
    <div class="page-2 p-r flex-col align-center">
      <h2>广告填充率低，接多家广告平台成本高？</h2>
      <div class="p-a right-box">
        <h3>广告聚合</h3>
        <span>1个SDK聚合国内外主流广告平台，低成本接入，填充率99%+</span>
        <p>广告聚合支持：Android、iOS、Unity</p>
        <my-button v-show="false" name="下载SDK" />
      </div>
      <div class="back-img2 p-a"></div>
    </div>
    <page-3 />
    <div class="page-4 p-r flex-col align-center">
      <h2>没有合适的变现场景，用户留存还低？</h2>
      <div class="left4 p-a">
        <h2>用户场景</h2>
        <span>
          多样化的用户场景，无需开发，让应用实现 “ 收益+、留存+、裂变+ ”
        </span>
        <p>在应用内接入由 ADTURING 研发的用户场景，收益归您所有</p>
        <my-button name="免费使用" @click.stop="LogInClick('login')" />
      </div>
      <div class="right4 p-a"></div>
    </div>
    <div class="page-5 p-r flex-col align-center">
      <h2>没有合适的变现场景，用户留存还低？</h2>
      <div class="left5 p-a"></div>
      <div class="right5 p-a">
        <h3>
          数据赋能
        </h3>
        <span>
          实时的数据统计，多维度的数据筛选，多样化的数据分析模型，大大提<br />升运营效率
        </span>
        <p>
          完整的广告指标体系，通过数据可视化呈现
        </p>
        <my-button name="免费使用" @click.stop="LogInClick('login')" />
      </div>
    </div>
    <div class="page-6 p-r flex-col align-center">
      <h2>
        ADTURING的成功案例
      </h2>
      <div class="flex-row page-border flex-center">
        <img-border
          :leftObj="{ name: '收益增长', per: '165%' }"
          :rightObj="{ name: '7日留存提升', per: '68%' }"
          buttonName="应用变现"
          class="mx-4"
          backgroundImage="top1"
        />
        <img-border
          :leftObj="{ name: '平均填充率', per: '99%' }"
          :rightObj="{ name: 'ARPDAU增长', per: '80%' }"
          buttonName="网赚变现"
          class="mx-4"
          backgroundImage="top2"
        />
        <img-border
          :leftObj="{ name: 'eCPM提升', per: '56%' }"
          :rightObj="{ name: 'LTV提升', per: '151%' }"
          buttonName="游戏变现"
          class="mx-4"
          backgroundImage="top3"
        />
      </div>
      <h2 class="last">了解ADTURING如何帮助您实现业务增长</h2>
      <my-button class="lianx_show" name="联系我们" @click.stop="routerFun" />
    </div>
  </div>
</template>

<script lang="ts">
import ImgBorder from "@/components/ImgBorder.vue";
import MyButton from "@/components/MyButton.vue";
import { defineComponent } from "vue";
import Page3 from "@/page/page3.vue";
import LogInClick from "../use/LogInClick";
import { useRouter } from "vue-router";

export default defineComponent({
  components: { MyButton, ImgBorder, Page3 },
  setup() {
    const router = useRouter();
    return {
      LogInClick,
      //   data
      routerFun: () => router.push("/contact"),
    };
  },
});
</script>

<style lang="scss" scoped>
.home {
  width: 100%; ///;
  // font-family: PingFangSC-Regular, PingFang SC;
  // background-color: khaki;
  overflow: hidden;
  p {
    font-size: 0.833vw;
  }
  .page-1 {
    height: 31.25vw;
    background: linear-gradient(
      176deg,
      rgba(77, 120, 231, 0) 0%,
      rgba(36, 63, 131, 0.1) 46%,
      rgba(36, 63, 131, 0.1) 46%,
      #051c50 75%,
      #001034 100%
    );
    background-image: url(~@/assets/image/page1.png);
    background-size: 100%;
    h2 {
      height: 4.375vw;
      font-size: 2.5vw;
      color: #ffffff;
      line-height: 4.375vw;
      margin-top: 6vw;
      font-weight: 600;
    }
    p {
      height: 1.719vw;
      font-size: 1.25vw;
      font-weight: 400;
      color: #ffffff;
      line-height: 1.719vw;
      margin: 1.1vw 0 2.2vw;
    }
  }
  .page-2 {
    height: 41.667vw;
    padding-top: 3.75vw;
    background: #f8f8f8;
    background-image: url(~@/assets/image/page2.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    h2 {
      height: 3.49vw;
      font-size: 1.667vw;
      font-weight: 600;
      color: #0a193e;
      line-height: 3.49vw;
      margin: 0;
    }
    .back-img2 {
      display: inline-block;
      width: 33.021vw;
      height: 33.021vw;
      top: 8vw;
      left: 5.729vw;
      background-size: 100% 100%;
      background-image: url(~@/assets/image/page2-l.png);
    }
    .right-box {
      width: 36.458vw;
      left: 53.333vw;
      top: 14.792vw;
      h3 {
        font-size: 1.667vw;
        font-weight: 600;
        color: #0a193e;
        line-height: 2.344vw;
      }
      span {
        font-size: 1.042vw;
      }
      p {
        font-size: 0.833vw;
        font-weight: 400;
        color: #0a193e;
        line-height: 1.146vw;
        margin: 1.25vw 0 2.5vw;
      }
    }
  }
  //   .page-3 {
  //     h2 {
  //       height: 3.49vw;
  //       font-size: 2.5vw;
  //       font-weight: 600;
  //       color: #ffffff;
  //       line-height: 3.49vw;
  //     }
  //     .back-img3 {
  //       width: 27.188vw;
  //       height: 25.469vw;
  //       background-color: lawngreen;
  //       top: 9.531vw;
  //       left: 62.5vw;
  //     }
  //     p {
  //       margin: 1.25vw 0 2.5vw;
  //     }
  //   }
  .page-4 {
    height: 41.667vw;
    background: #f8f8f8;
    h2 {
      height: 3.49vw;
      font-size: 1.667vw;
      font-weight: 600;
      color: #0a193e;
      line-height: 3.49vw;
      margin-top: 4.167vw;
    }
    .left4 {
      left: 7.292vw;
      top: 14.74vw;
      h3 {
        line-height: 2.344vw;
        font-size: 1.667vw;
        color: #0a193e;
        width: 40.052vw;
      }
      span {
        font-size: 1.25vw;
      }
      p {
        margin: 1.25vw 0 2.5vw;
      }
    }
    .right4 {
      left: 60.521vw;
      top: 8.958vw;
      width: 36vw;
      height: 36vw;
      z-index: 90;
      background-size: 16.855vw 31.602vw;
      background-image: url(~@/assets/image/Phone2.png),
        url(~@/assets/image/Phone3.png), url(~@/assets/image/Phone1.png);
      background-repeat: no-repeat;
      background-position: left 7.448vw top 0vw, left 16.979vw top 5.99vw,
        left 0 top 4.74vw;
    }
  }
  .page-5 {
    height: 41.667vw;
    background: linear-gradient(181deg, #142d67 0%, #000000 100%);
    h2 {
      height: 3.49vw;
      font-size: 1.667vw;
      color: #ffffff;
      line-height: 3.49vw;
      margin-top: 4.167vw;
    }
    .left5 {
      width: 28.646vw;
      height: 28.646vw;
      left: 7.396vw;
      top: 8.646vw;
      background-image: url(~@/assets/image/page5.png);
      background-size: 100% 100%;
    }
    .right5 {
      top: 15.938vw;
      left: 53.333vw;
      color: #fff;
      h3 {
        height: 2.344vw;
        font-size: 1.667vw;
        line-height: 2.344vw;
        letter-spacing: 0.208vw;
        margin: 0 0 1.667vw;
      }
      span {
        font-size: 1.25vw;
      }
      p {
        height: 1.146vw;
        font-size: 0.833vw;
        line-height: 1.146vw;
        letter-spacing: 0.104vw;
        margin: 1.25vw 0 2.5vw;
      }
    }
  }
  .page-6 {
    height: 72.76vw;
    h2 {
      margin-top: 4.167vw;
      height: 3.49vw;
      font-size: 1.667vw;
      color: #0a193e;
      line-height: 3.49vw;
      &.last {
        margin: 10vw 0 4.167vw;
        font-size: 2.083vw;
      }
    }
    .page-border {
      padding: 4.427vw 6.25vw 0;
    }
  }
}
</style>
