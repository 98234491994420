
import MyButton from "@/components/MyButton.vue";
import { defineComponent, ref } from "vue";
import LogInClick from "../use/LogInClick";

export default defineComponent({
  components: { MyButton },
  setup() {
    const dataTxt = {
      policy: {
        head: "缺少变现经验，广告收益提升难？",
        txt: "无需变现经验，<br/>大数据 + AI 算法 =智能化决策",
        p: "基于瀑布流分层模式，系统进行自动优化，找到广告收益的更优解",
      },
      bidding: {
        head: "缺少变现经验，广告收益提升难？",
        txt: "所有广告需求方同时竞价，最终展示价格最高的广告",
        p:
          "提供全球流行的应用内竞价（In-App Bidding）模式，让每一次广告展示获得最高价",
      },
    };
    const types = [
      { name: "智能策略", type: "policy" },
      { name: "应用内部竞价", type: "bidding" },
    ];
    const type = ref(types[0].type);
    return {
      type,
      types,
      dataTxt,
      LogInClick,
    };
  },
});
