<template>
  <div class="img-border flex-col flex-center">
    <div class="top-img p-r w-100 " :class="[backgroundImage]"></div>
    <div class="p-a bot-box flex-row flex-center">{{ buttonName }}</div>
    <div class="flex-row">
      <div class="code flex-col flex-center">
        <h2>{{ leftObj.per }}</h2>
        <span>{{ leftObj.name }}</span>
      </div>
      <div class="code flex-col flex-center">
        <h2>{{ rightObj.per }}</h2>
        <span>{{ rightObj.name }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    leftObj: {
      type: Object,
      default: () => ({
        name: "名字",
        per: "概率",
      }),
    },
    backgroundImage: {
      type: String,
      default: "",
    },
    buttonName: {
      type: String,
      default: "按钮",
    },
    rightObj: {
      type: Object,
      default: () => ({
        name: "名字",
        per: "概率",
      }),
    },
  },
  name: "ImgBorder",
  setup() {
    //
  },
});
</script>

<style lang="scss" scoped>
.img-border {
  width: 26.042vw;
  height: 32.292vw;
  background: linear-gradient(135deg, #dfebf2 0%, #f8f8f8 100%);
  box-shadow: 2.083vw 2.083vw 2.292vw -0.521vw rgba(87, 135, 195, 0.08);
  border-radius: 1.042vw;
  border: 0.521vw solid #ffffff;

  .code {
    width: 13.021vw;
    height: 16.25vw;
    border: 0.2vw solid #fff;
    h2 {
      height: 4.063vw;
      font-size: 2.917vw;
      color: #5ca4f1;
      line-height: 4.063vw;
      margin: 0;
    }
    span {
      margin-top: 0.833vw;
      height: 1.146vw;
      font-size: 0.833vw;
      font-weight: 400;
      color: #0a193e;
      line-height: 1.146vw;
    }
  }
  .bot-box {
    width: 8.75vw;
    height: 3.385vw;
    background: linear-gradient(135deg, #f8f8f8 0%, #dfebf2 100%);
    box-shadow: 0.521vw 1.042vw 1.563vw -1.042vw rgba(87, 135, 195, 0.5);
    border-radius: 0.313vw;
    border: 0.16vw solid #ffffff;
    z-index: 9;
    font-size: 1.042vw;
    color: #132145;
    line-height: 1.458vw;
  }
  .top-img {
    height: 15.625vw;
    border-radius: 0.56vw;
    overflow: hidden;
    background-size: 100% auto;
    &.top1 {
      background-image: url(~@/assets/image/top1.png);
    }
    &.top2 {
      background-image: url(~@/assets/image/top2.png);
    }
    &.top3 {
      background-image: url(~@/assets/image/top3.png);
    }
    // &::before {
    //   content: "";
    //   position: absolute;
    //   width: 100%;
    //   height: 100%;
    //   background: #000;
    //   opacity: 0.56;
    //   z-index: 9;
    // }
  }
}
</style>
